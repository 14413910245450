import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Video1 from "../components/Video-01";

function Video01Container() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoverUrl, setHoverUrl] = useState("");

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            //  console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            //   console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Kathryn's Story | But God Book</title>
                <meta
                    name="description"
                    content="Watch Kathryn Kirk, a female pastor, testify of her journey of experiencing revival and deliverance in her church through the power of God and social media outreach."
                />
            </Helmet>
            <Video1
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>
    );
}

export default Video01Container;