import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'
import Navbar from "../components/Navbar";


export default ({ screenSize }) => {

    // const vidRef = useRef();

    // useEffect(() => { vidRef.current.play(); }, []);


    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#000",
            //  overflow: `auto`,
            height: '100vh',
            width: '100vw',
        },
        buttonFloat: {
            // position: "absolute",
            // top: 10,
            // right: 10,
            // display: "flex",
            // justifyContent: "flex-end",
            // borderRadius: "50px",
            // height: '100px',
            // width: '100vw'



            position: "absolute",
            top: 30,
            right: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //  borderRadius: "50px",
            // height: "50px",
            // width: "50px",
            backgroundColor: "transparent",
            color: "#fff",
            fontWeight: "bold",
            border: 'none',
            padding: 0,
            margin: 0
            //backgroundColor: "green",
        },
        // videoContainer: {
        //     display: "flex",
        //     flex: 1,
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     height: `${screenSize > 800 ? ('100vh') : ('20vh')}`,
        //     //    width: `${screenSize > 800 ? (<></>) : ("100vw")}`,
        //     //  width: '686px',
        //     //width: '100vw',
        //     padding: '0px',
        //     backgroundColor: '#000',
        //     margin: '0px 0px 20px'
        // },
        videoContainer: {
            display: "flex",
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: `${screenSize > 800 ? ('100vh') : ('100%')}`,





            // height: '500px',
            // width: '500px',


            //    width: `${screenSize > 800 ? (<></>) : ("100vw")}`,
            //  width: '686px',
            //width: '100vw',
            padding: '0px',
            backgroundColor: '#000',
            margin: '0px 0px 20px'
        },
        video: {
            display: "flex",
            // height: '100px',
            // width: '100px',
        },
        bottom: {
            display: "flex",
            alignItems: 'center',
            alignSelf: 'center',

            flexDirection: 'column',
            zIndex: 100,
            position: "fixed",
            bottom: `${screenSize > 800 ? (0) : (0)} `,
            // top: 0,
            // right: 0,
            margin: `${screenSize > 800 ? ("100px 0px 130px") : ("100px 20px 50px")}`,
        },
        textOverbutton: {
            fontSize: `${screenSize > 800 ? ("22px") : ("20px")}`,
            fontFamily: "BigCaslon",
            color: '#fff',
            display: "flex",
            alignSelf: 'center',
            margin: "0px 0px 20px",
            padding: 0,
            zIndex: 200,
            textAlign: 'center'
        },
        textOverbuttonTop: {
            fontSize: `${screenSize > 800 ? ("18px") : ("18px")}`,
            fontFamily: "BigCaslon",
            color: '#fff',
            display: "flex",
            alignSelf: 'center',
            justifyContent: 'center',
            margin: "20vh 0px 0px",
            // margin: "235px 0px 0px",
            padding: "15px",
            zIndex: 200,
            textAlign: 'center',
            backgroundColor: '#000',
            width: "100vw"
        },
        textOverbuttonTop: {
            fontSize: `${screenSize > 800 ? ("18px") : ("18px")}`,
            fontFamily: "BigCaslon",
            color: '#fff',
            display: "flex",
            alignSelf: 'center',
            justifyContent: 'center',
            margin: "20vh 0px 0px",
            // margin: "235px 0px 0px",
            padding: "15px",
            zIndex: 200,
            textAlign: 'center',
            backgroundColor: '#000',
            width: "100vw"
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            width: `${screenSize > 800 ? ('712px') : ("70vw")} `,
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            //   margin: `${screenSize > 800 ? ("100px 0px 130px") : ("100px 40px 30px")}`,
            backgroundColor: '#fff',
            borderRadius: '50px',
            border: '1px solid #fff',
            //   fontWeight: 600
            // position: "fixed",
            // // top: 0,
            // // right: 0,
            // bottom: `${screenSize > 800 ? (0) : ("0")} `,
            //  left: 0,
            zIndex: 100,
        },
        textbutton: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#000',
            display: "flex",
            alignSelf: 'center',
            margin: 0,
            padding: 0
        },
        textbuttonMobile: {
            fontSize: 18,
            fontFamily: "BigCaslon",
            color: '#000',
            display: "flex",
            alignSelf: 'center',
            margin: 0,
            padding: 0,
            //
            padding: "5px 20px 5px 10px",
            textAlign: "left",
            lineHeight: '25px'
        }
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">

                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        position: "absolute",
                        zIndex: "10",
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        objectFit: 'cover',
                        display: "flex",
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        //     height: `${screenSize > 800 ? ('100vh') : ('100%')}`,
                        padding: '0px',
                        backgroundColor: '#000',
                        //    margin: '0px 0px 20px'
                    }}>
                    <source src='/assets/videos/VIDEO-HOME.mp4' type="video/mp4" />
                </video>


                {/* <div style={styles.videoContainer}>
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{
                            position: "absolute",
                            zIndex: "10",
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            objectFit: 'cover'
                        }}>
                        <source src='/assets/videos/VIDEO-HOME.mp4' type="video/mp4" />
                    </video>
                </div> */}

                {/* <ReactPlayer
                        className='react-player'
                        url='/assets/videos/VIDEO-HOME.mp4'
                        style={styles.video}
                        //   height={screenSize > 800 ? ("100%") : ("100vh")}
                        // width={screenSize > 800 ? ('100%') : (<></>)}
                        height={screenSize > 800 ? ("100%") : ("300px")}
                        width={screenSize > 800 ? ('100%') : (<></>)}
                        // controls={true}
                        playing={true}
                        muted={true}
                        loop={true}
                    /> */}
                {/* <ReactPlayer
                        className='react-player'
                        url='/assets/videos/VIDEO-HOME.mp4'
                        style={styles.video}
                        height={screenSize > 800 ? ("100%") : ("400px")}
                        width={screenSize > 800 ? ('100%') : ('100%')}
                        controls={true}
                        playing={true}
                        muted={true}
                    /> */}
                {/* <img
                        src="https://i.postimg.cc/zfRCW9XN/home.png"
                        height={screenSize > 800 ? ("100%") : ("100%")}
                        width={screenSize > 800 ? ('100%') : ("100%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                    /> */}
                <div style={styles.bottom}>

                    {/* <h2 style={styles.textOverbutton}>GIVE THE GIFT OF GOD'S REDEMPTIVE STORIES THIS CHRISTMAS </h2> */}
                    <button
                        // onClick={() => window.open("https://www.shop.butgodbook.co/product-page/but-god", "_blank")}
                        onClick={() => window.open("https://www.shop.butgodbook.co/shop", "_blank")}
                        style={styles.buttonEshop}
                    >
                        {screenSize > 800 ? (<h1 style={styles.textbutton}>ORDER "BUT GOD" BOOK HERE</h1>) : (<h1 style={styles.textbuttonMobile}>ORDER "BUT GOD" BOOK HERE</h1>)}
                        {/* {screenSize > 800 ? (<h1 style={styles.textbutton}>Order Now</h1>) : (<h1 style={styles.textbuttonMobile}>Order Now</h1>)} */}
                        {/* {screenSize > 800 ? (<h1 style={styles.textbutton}>Pre-Order "BUT GOD." Here</h1>) : (<h1 style={styles.textbuttonMobile}>Pre-Order</h1>)} */}
                        {/* {screenSize > 800 ? (<h1 style={styles.textbutton}>Sign up for book release updates</h1>) : (<h1 style={styles.textbuttonMobile}>Sign up for book release updates</h1>)} */}
                        <img
                            src="https://i.postimg.cc/tJ61cwP3/left-arrow.png"
                            height="24"
                            alt="Image"
                        />
                    </button>
                </div>

            </div>
        </>
    );
};