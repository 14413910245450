import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Story4 from "../components/Story-04";

function Storty04Container() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoverUrl, setHoverUrl] = useState("");

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            //  console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            //   console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Baylor's Story | But God Book</title>
                <meta
                    name="description"
                    content="Baylor always said she was a Christian, but was living a lifestyle that was far from God. She didn't know she was lost, until a Friday night changed everything. Read more of her story here."
                />
            </Helmet>
            <Story4
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>
    );
}

export default Storty04Container;