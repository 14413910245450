/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "./NavbarTransparent";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleHover, handleHoverOut, hoverUrl }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            // backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            backgroundColor: '#000',
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")}`,
            color: '#fff',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 0vh") : ("30vh 0px 5vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
            position: "relative",
            zIndex: '100',
            top: `${screenSize > 800 ? ('70px') : ('140px')} `,
            left: 0,
            // right: 0,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: 20,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        textDescriptionbig: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: `${screenSize > 800 ? ("70px") : ("30px")}`,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: `${screenSize > 800 ? ("70px") : ("30px")}`,
            marginTop: 50,
            marginBottom: 50,
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            gap: '20px',
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "50px 0px",
            flexWrap: `${screenSize > 800 ? ("nowrap") : ("wrap")}`,

        },
        imagePadding: {
            padding: '10px',
            // backgroundColor: '#000',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            width: `${screenSize > 800 ? (<></>) : ("80%")} `,
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: '391px',
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: "100px 0px 130px",
            // backgroundColor: 'red',
            borderRadius: '50px',
            border: '1px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        footer: {
            display: "flex",
            margin: "0px 60px 150px",
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: `${screenSize > 800 ? ("0px 0px 0px 60px") : ("0px 20px")}`,
            margin: '50px 0px 0px'
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: `${screenSize > 800 ? ("921px") : ("80vw")}`,
            height: "2px",
            alignSelf: 'flex-start',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: "0px 20px 0px",
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        textDate: {
            display: "flex",
            margin: "20px 0px 0px",
            fontSize: 12,
            color: '#000',
            lineHeight: "14px",
        },
        moreStoriesText: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            margin: "100px 0px 83px",
            margin: `${screenSize > 800 ? ("100px 0px 83px") : ("70px 0px 30px")}`,
            //margin: "30px 30vw",
            fontSize: 12,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            fontWeight: 700
        },
        storiesContainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: 20,
            // color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ("0px 50px") : ("0px 5px")}`,
            margin: `${screenSize > 800 ? ("0px 0px 150px") : ("0px 0px 100px")}`,
            // backgroundColor: 'yellow'
        },
        photo: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            //  margin: '0px 10px'
            margin: '0px'
        },

        image: {
            display: "flex",
            height: '700px',
            width: '411px',

            backgroundColor: 'purple',
            // backgroundImage: `url(${hoverUrl})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
        },


        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    fontSize: 150,
                    color: '#000',
                    height: "436px",
                    // backgroundImage: `url(${"https://i.postimg.cc/Y9c2rNfr/SARAH-GERALD.jpg"})`,
                    backgroundColor: '#000',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                    margin: "0px 0px 150px"
                }}>
                    <h1 style={styles.titleContainer}>HOPE</h1>
                </div>
                <div style={styles.imageContainer}>
                    <div style={styles.imagePadding}>
                        <img
                            src="https://i.postimg.cc/qRMZSK7H/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0237-RETOUCHED.jpg"
                            //    height="1382"
                            height={screenSize > 800 ? ('829px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover" }}
                            alt="Image"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>Motherhood. What was once my greatest fear, has become God’s greatest gift in
                        my life. <br /> <br />

                        When I was younger, I took for granted what it meant to become a mother. I had
                        a great mom, but for some reason I thought motherhood was limiting. I had my
                        heart set on becoming a career woman. Marriage and motherhood were
                        secondary to the desires in my heart. They were out of sight, and out of mind
                        until I had "fulfilled my dreams". <br /> <br /> <br />

                        When I was seventeen, I was given a diagnosis of Poly Cystic Ovarian
                        Syndrome. Most women with this diagnosis have a really hard time getting
                        pregnant and many can't conceive at all. At the time of my diagnosis, I was
                        young and focused on going to college and becoming a professional singer. I
                        didn’t understand or care about the impact it would have on my body and my
                        mindset about motherhood.<br /> <br />
                        Seven years later, I would meet and marry my incredible husband, David. My
                        career hadn't taken off the way I wanted and God had been taking me down a
                        different path of full-time ministry. A lot had changed in my heart over the years
                        and I now lived open handed to God's plans instead of forcing my own. Little
                        did I know how important that posture would become for me. When we got
                        married, we decided to follow the popular "five-year plan" before trying to have
                        kids. We were young and wanted some years by ourselves. I was also scared. I
                        had fears about becoming a mom. I worried I wouldn't be a good mother. I even
                        still worried I would have to give up all my personal dreams, even the little ones,
                        to be a mom. But mostly, I was scared my PCOS diagnosis would prevent me
                        from ever becoming a mother. So waiting was safe. It bought me time, or so I
                        thought. <br /> <br />

                        Three years into “our plan”, I kept feeling the Lord nudge me to go to see my
                        doctor and have my hormones tested. Until now, I hadn't done anything in terms
                        of managing my PCOS. Even though we had decided to wait on having kids, the
                        fears that went along with my diagnosis were getting louder in my head. I knew
                        there was a chance that our journey to have a baby could take a while and I
                        wanted to be prepared. I met with my OBGYN and we ran some tests. Upon my
                        results, he suggested more testing and referred me to another doctor.
                        Immediately, I knew the road just got harder. I wasn't really sure why the Lord
                        was leading me down this road since I didn't "feel ready" to have a baby, but I
                        also sensed the Lord asking me to trust him and follow him down this path,
                        ready or not. <br /> <br />






                    </h3>

                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/P5wvjV7d/hope-1.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />

                    <img
                        src="https://i.postimg.cc/j5XJ1WFN/hope-2.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    <img
                        src="https://i.postimg.cc/dQ5ymydc/hope-3.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    {/* <h3 style={styles.textDescription}>The next day I returned to the city and knew things were about to move quickly with next steps
                        for my treatment plan. My google search history became a diary of what was filling my mind
                        “stage 4 cancer healing” “miracle healing of cancer”“best oncologists in New York” “31 years old
                        stage 4 diagnosis”. I had quickly become very aware of the facts and reality. The whispers of
                        doctors and staff in hospital rooms grew loud and faith - in those environments - started to feel
                        foolish. I had already been crushed so to believe again in this place of what is deemed a
                        “terminal diagnosis” felt like it took everything in me to believe and it’s scary to believe again in a
                        place where you have already been let down. It was almost like “ok God, I believed you would
                        be with me in an early stage diagnosis, but now that it is stage four - that feels like proof you’ve
                        abandoned me and this is it.” I would be full of faith in one moment and the next, I felt depleted
                        of hope. There was a constant tension of faith and fear. Grief became like a bucket of water
                        that wouldn’t empty and sat beside me. I didn’t know what to do or where to start, would I die
                        from this or would God really heal me to the point of fully living life again? I felt half alive some
                        of the days in that season. I fought to get before the Lord in prayer every day, that time with
                        Him was my lifeline.</h3> */}
                    <h3 style={styles.textDescription}>
                        Six months later, I would find myself sitting across from a fertility specialist
                        listening to him tell me and my husband that we had less than a 3% chance of
                        ever getting pregnant and we were not good candidates for any type of fertility
                        intervention. He suggested we go home and begin considering alternative paths
                        to parenthood. We were shocked. We were in our mid-twenties. We thought we
                        had time. We never considered the door to completely shut.
                    </h3>
                    <h3 style={styles.textDescription}>
                        But upon hearing the doctor’s report, something awakened inside my husband
                        and me at the same time…supernatural faith. I’ll never forgot my husband
                        looking at the doctor and saying,

                    </h3>

                    {/* QUOTE HERE */}
                    <h3 style={styles.textDescriptionbig}>
                        “So 3%, huh? Sounds like we have a chance.”
                    </h3>

                </div>

                <div style={styles.textContainer}>

                    <h3 style={styles.textDescription}> In that moment, we knew we had a choice to partner with the doctor’s words
                        and the diagnosis on paper or believe that God could do something greater.
                        <br /> <br />
                        The next few days of our life were filled with a lot of conservations, tears and
                        prayers. I was wrestling through all of my emotions about my infertility, the
                        doctor's words and my own words. I found myself remembering all the times I
                        had spoken careless words out of fear and self-preservation. Every time I had
                        said, "I don't know if we want kids" or "I don't know if I'm cut out for
                        motherhood" felt like daggers I had plunged into my own heart. In one moment
                        I had all the clarity I needed. Of course I wanted to be a mother. I couldn't
                        imagine our lives without children. I was filled with remorse that I had been so
                        reckless with my words. I spent time repenting to Lord and asking him to have
                        mercy on me. Our words hold life or death and suddenly I could see all the
                        death I had unknowingly spoken over myself out of fear for years. So I began to
                        pray and repent, “God, please forgive me for my careless words. I do want to
                        have children. I do want to be a mother. God, have mercy on me and heal my
                        body. Make a way for us to have a baby.” I can’t explain it naturally, but in that
                        moment I felt God’s presence pour over me. I felt him say, “Hope, I have
                        promised parenthood to you and David. Trust me.”
                        <br />
                        <br />
                        A wave of peace and confidence flooded my heart and my mind. I knew God
                        would keep his word. I didn’t know how or when, and I was open handed once
                        again to God's plans. I knew God would be faithful to keep His promise. David
                        and I also knew it was time to invite our church community and friends into this
                        journey of faith with us. We began to share our story and asked people to
                        believe and pray for God to do a miracle.
                        <br />
                        <br />
                        To our shock and surprise, a few months later, we were pregnant. God had done
                        what the doctors said was impossible. We were shocked, elated, and grateful.
                        Since we were technically still under the care of a fertility specialist, it meant
                        they would oversee my care since I was considered a high risk pregnancy. Every
                        week I would go to my appointment and hear the nurses say, "You know
                        you are high risk and your body isn't making the hormones you need to
                        sustain this pregnancy to full-term." I had one response every time, "I know
                        I can't, but God can”. <br /> <br />
                        During my pregnancy, we saw God do miracle after miracle in my body and in
                        the life of our baby. He sustained us both. God was in control and he had a plan
                        and purpose for this baby. In March of 2009, our miracle daughter, Olivia Grace,
                        was born healthy and whole. She was God’s miracle power wrapped up in 6
                        lbs, 6oz. At the time, we thought Olivia was just our miracle baby. We hadn’t
                        taken the time to consider not only had God provided this miracle child, but He
                        had also healed my body. Well, a week after Olivia’s first birthday we discovered
                        we were pregnant again. God had gone above and beyond what we could even
                        think or imagine. We delivered our second miracle daughter, Brooklyn Elizabeth,
                        that following December.

                        <br />
                        <br />

                        A few years after my daughters were born, I was reminded of a word someone
                        had given me a few years before I got married. It simply said, "God is going to
                        fulfill every promise He has given to you, but it won't be how you imagined. It
                        will be in the opposite order of what you have planned". I had always pictured
                        my life going one way...career, marriage, children. But life threw me some
                        unexpected turns. Instead it went marriage, miracle children and a later-in-life
                        career. Not what I planned, but what I needed. A plan where God met me and
                        provided exactly what I needed at every turn and at the right time. Everything
                        thing I ever dreamed of doing or being called to do only happened AFTER I got
                        married and had my children. God knew the best path for me. He knew marriage
                        and motherhood would anchor me and refine me. Motherhood has revealed
                        God's miracle power and unending faithfulness in my life. I couldn't heal my
                        body, heart and mind. They were sick and I needed God to do a what I couldn't.
                        God healed me in countless ways through motherhood and continues to do so
                        each day. Every time I look at my daughters I am reminded that even when I
                        can’t, God can.

                        <br />
                        <br />

                        When I look back over my life and journey to motherhood, I am overwhelmed by
                        the faithfulness and kindness of God. So much of my life has been about letting
                        go of what I can’t control, overcoming my fears, and trusting God has a better
                        plan than me. God knew motherhood would be the greatest catalyst for change
                        in my life. Motherhood has made me put all of hope and trust in God. It's
                        revealed my strengths and weakness and my very desperate need for God. It’s
                        truly God’s greatest gift to me.

                        <br />
                        <br />



                    </h3>
                </div>

                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/qRMZSK7H/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0237-RETOUCHED.jpg"
                        //      height="650"
                        height={screenSize > 800 ? ('520px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("100%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    {/* <iframe
                        height={screenSize > 800 ? ('520px') : ("170px")}
                        width={screenSize > 800 ? ("924") : ("80%")}
                        src="https://www.youtube.com/embed/glUBrILRj60" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe> */}
                </div>
                <div style={styles.singleFooter}>
                    <div style={styles.line} />
                    <div style={styles.textDate}>JUNE 20TH 2021</div>
                </div>
                <h2 style={styles.moreStoriesText}>MORE STORIES</h2>
                <div style={styles.storiesContainer}>
                    <Link to="/stories/holly"
                        onMouseOver={() => handleHover(true, "1")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "1" ? (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/Bv9PbrcK/Holly-Hover.png"
                                        src="https://i.postimg.cc/xTqXCCWy/Holly-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/X7Fd25d6/Holly.png"
                                        src="https://i.postimg.cc/PrLqH7wS/Holly.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/mia-and-joren"
                        onMouseOver={() => handleHover(true, "3")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "3" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/VL0Nt7NB/Mia-Joren-Hover.png"
                                        src="https://i.postimg.cc/7PRxKNW0/Mia-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/vBkxh8mm/Mya-Joren.png"
                                        src="https://i.postimg.cc/LsvSLkPq/Mia.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/baylor"
                        onMouseOver={() => handleHover(true, "4")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "4" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/L4zCsStz/Baylor-Hover.png"
                                        src="https://i.postimg.cc/R00SPb41/Baylor-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/qMXghx2q/Baylor.png"
                                        src="https://i.postimg.cc/dV5vTmrh/Baylor.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                </div>
            </div>
        </>
    );
};
