import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import BehindTheScenes from "../components/BehindTheScenes";

function BehindTheScenesContainer() {

    const [hoverActive, setHoverActive] = useState(false);
    const [hoverUrl, setHoverUrl] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Behind The Scenes | But God Book</title>
                <meta
                    name="description"
                    content="The But God book features inspirational testimonies of God's goodness in the lives of 40 people. Learn more about the journey behind creating the But God Book."
                />
            </Helmet>
            <BehindTheScenes
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>
    );
}

export default BehindTheScenesContainer;