import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import ExhibitionStory from "../components/ExhibitionStory";

function ExhibitionStoryContainer() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    return (
        <>
            <Helmet>
                <title>But God Exhibition | But God Book</title>
                <meta
                    name="description"
                    content="Join us for a one-night-only exhibition in Nashville, TN on the November 2, 2022 to celebrate the launch of The But God Book. Get your tickets here."
                />
            </Helmet>
            <ExhibitionStory
                screenSize={windowWidth}
            />
        </>

    );
}

export default ExhibitionStoryContainer;