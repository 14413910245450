/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "./NavbarTransparent";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleHover, handleHoverOut, hoverUrl }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            // backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            backgroundColor: '#000',
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")}`,
            color: '#fff',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 0vh") : ("30vh 0px 5vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
            position: "relative",
            zIndex: '100',
            top: `${screenSize > 800 ? ('70px') : ('140px')} `,
            left: 0,
            // right: 0,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: 20,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        textDescriptionbig: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: `${screenSize > 800 ? ("70px") : ("30px")}`,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: `${screenSize > 800 ? ("70px") : ("30px")}`,
            marginTop: 50,
            marginBottom: 50,
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            gap: '20px',
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "50px 0px",
            flexWrap: `${screenSize > 800 ? ("nowrap") : ("wrap")}`,

        },
        imagePadding: {
            padding: '10px',
            // backgroundColor: '#000',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            width: `${screenSize > 800 ? (<></>) : ("80%")} `,
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: '391px',
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: "100px 0px 130px",
            // backgroundColor: 'red',
            borderRadius: '50px',
            border: '1px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        footer: {
            display: "flex",
            margin: "0px 60px 150px",
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: `${screenSize > 800 ? ("0px 0px 0px 60px") : ("0px 20px")}`,
            margin: '50px 0px 0px'
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: `${screenSize > 800 ? ("921px") : ("80vw")}`,
            height: "2px",
            alignSelf: 'flex-start',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: "0px 20px 0px",
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        textDate: {
            display: "flex",
            margin: "20px 0px 0px",
            fontSize: 12,
            color: '#000',
            lineHeight: "14px",
        },
        moreStoriesText: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            margin: "100px 0px 83px",
            margin: `${screenSize > 800 ? ("100px 0px 83px") : ("70px 0px 30px")}`,
            //margin: "30px 30vw",
            fontSize: 12,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            fontWeight: 700
        },
        storiesContainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: 20,
            // color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ("0px 50px") : ("0px 5px")}`,
            margin: `${screenSize > 800 ? ("0px 0px 150px") : ("0px 0px 100px")}`,
            // backgroundColor: 'yellow'
        },
        photo: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            //  margin: '0px 10px'
            margin: '0px'
        },

        image: {
            display: "flex",
            height: '700px',
            width: '411px',

            backgroundColor: 'purple',
            // backgroundImage: `url(${hoverUrl})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
        },


        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    fontSize: 150,
                    color: '#000',
                    height: "436px",
                    // backgroundImage: `url(${"https://i.postimg.cc/Y9c2rNfr/SARAH-GERALD.jpg"})`,
                    backgroundColor: '#000',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                    margin: "0px 0px 150px"
                }}>
                    <h1 style={styles.titleContainer}>ALYSSA</h1>
                </div>
                <div style={styles.imageContainer}>
                    <div style={styles.imagePadding}>
                        <img
                            src="https://i.postimg.cc/K88LjQCX/2024-06-30-But-God-2024-Portraits-Nashville-Corwin1502-RETOUCHED.jpg"
                            //    height="1382"
                            height={screenSize > 800 ? ('829px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover" }}
                            alt="Image"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>The place where hope and suffering collide is often the place where miracles happen.
                        In June 2021, my days suddenly became filled with back to back doctor appointments, seeing
                        specialists, researching hospitals and options. My purse was full of brochures and sticky notes
                        with random notes, email addresses and websites. The walls in my apartment were decorated
                        with 3x5 notecards with written scripture verses on healing and God’s character. I was entering
                        a battle and as I was bracing myself holding my breath, I look back and see that all the while He
                        was writing my victory. <br /> <br />

                        I was glued to my phone awaiting the biopsy results and although I was aware of the possibility
                        due to the countenance of the doctor’s - it’s true that nothing can prepare you for the moment
                        you hear “they found cancer.” <br /> <br /> <br />

                        Cancer had never been something my family had been closely impacted by, hearing the
                        diagnosis was cancer was surreal. My heart broke in a way that it never had before. I couldn’t
                        rationalize having cancer in my thirties, not that loss always makes sense...but I was grasping to
                        understand. The doctors were fairly certain it was early stage breast cancer. About a month
                        after the initial diagnosis I underwent more scans and biopsies. It was mid July, I was upstate
                        New York at a home a friend had graciously given me the keys to to retreat. It was the last day
                        of the trip and we had planned to drive back to Brooklyn early afternoon but I didn’t feel peace in
                        leaving yet. A few hours later my phone rang, it was my oncologist with the biopsy results to
                        confirm the stage of the cancer. The silence and tone of her voice led into what felt like a death
                        sentence. “It’s what we thought, its stage four.” A liver biopsy revealed the cancer had spread
                        outside of the initial tumor and was now on organs and bones, the type of cancer they found
                        was aggressive but treatable. There had been so many prayers and people surrounding me in
                        faith, I had come to a place where I really believed the cancer hadn’t progressed and that God
                        was going to heal me. To hear it had spread, I was completely crushed again. It felt like death
                        was staring me in the face. All of my dreams, desires, and promises I felt the Lord had for me
                        flashed before me. It felt like they were stolen in that moment. <br /> <br />
                        I felt completely numb. Like when you have a ringing in your ears that drowns out the sound and
                        your eyes can’t seem to focus..life didn’t feel real I was feeling everything and nothing all at
                        once. From complete faith to being consumed with fear and death. I had to get out so I went
                        outside to go for a walk alone. Deep disappointment came again it all felt like whiplash, I felt
                        abandoned by God. Tears flooded my eyes and I stopped and sat in the middle of this forest
                        near the home. In desperation I asked God “where are you in this?”
                        I’ve loved Jesus my whole life and have known the Lord’s protection from when I was in my
                        mother’s womb, to moments as a little girl - and now this? I couldn’t understand why God didn’t
                        stop the cancer in my body. And even though I felt abandoned by Him in that moment, my soul
                        couldn’t help but turn to Him still. <br /> <br />

                        The sun was starting to set and I was hoping for a visitation or audible voice from the Lord
                        because the moment warranted I needed to know He was with me. I sat a little longer, gold light
                        was dancing on the trees from the sun setting. There was a gentle breeze and I felt peace wash
                        over me. It was supernatural peace and comfort that I knew was Him. Creation has a beautiful
                        way of displaying God’s glory in moments where it feels He is silent. I walked back to the house
                        still empty but I felt His peace with me this time. That night I could barely sleep. Around 3:00am I
                        slowly got out of bed because there was so much pain in my body from the progression of the
                        disease and stress. I walked to another room in the house and said again “Lord show me where
                        you are in this.” It was one of the sweetest moments with the Lord I can remember. A presence
                        came into the room and He spoke into my heart that night that out of this place of suffering there
                        would be a miracle and He was going to heal me and be my Defender. As powerful as that night
                        was and other prayer moments throughout that season, it became a fight to hold faith. Every
                        day I was faced with all the loss that would come with cancer. <br /> <br />






                    </h3>

                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/6QGDsVWR/2024-06-30-But-God-2024-Portraits-Nashville-Corwin1684-RETOUCHED.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    <img
                        src="https://i.postimg.cc/sgXPMJcS/2.png"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    <img
                        src="https://i.postimg.cc/15McRdps/3.png"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>The next day I returned to the city and knew things were about to move quickly with next steps
                        for my treatment plan. My google search history became a diary of what was filling my mind
                        “stage 4 cancer healing” “miracle healing of cancer”“best oncologists in New York” “31 years old
                        stage 4 diagnosis”. I had quickly become very aware of the facts and reality. The whispers of
                        doctors and staff in hospital rooms grew loud and faith - in those environments - started to feel
                        foolish. I had already been crushed so to believe again in this place of what is deemed a
                        “terminal diagnosis” felt like it took everything in me to believe and it’s scary to believe again in a
                        place where you have already been let down. It was almost like “ok God, I believed you would
                        be with me in an early stage diagnosis, but now that it is stage four - that feels like proof you’ve
                        abandoned me and this is it.” I would be full of faith in one moment and the next, I felt depleted
                        of hope. There was a constant tension of faith and fear. Grief became like a bucket of water
                        that wouldn’t empty and sat beside me. I didn’t know what to do or where to start, would I die
                        from this or would God really heal me to the point of fully living life again? I felt half alive some
                        of the days in that season. I fought to get before the Lord in prayer every day, that time with
                        Him was my lifeline.</h3>
                    <h3 style={styles.textDescription}>
                        I had an appointment scheduled to review the updated staging and talk about next steps. Before
                        I went to the appointment I felt the Holy Spirit say</h3>
                    <h3 style={styles.textDescriptionbig}>
                        “I will be with you and there will always be
                        another option but it won’t be spoken of in the room.
                    </h3>

                </div>

                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>I went into the room and my oncologist put
                        the scan on the screens, she pointed to the red “hot spots” as she referred to them as - places
                        the cancer was in my body. I hung my head in defeat barely being able to look at the scan I was
                        so devastated. The cancer had really spread, seeing the scan felt like another blow confirming
                        the “death sentence” that felt like had just been served to me. My oncologist got out of her chair,
                        knelt down in front of me and said “do you understand what this all means…do you want to
                        know the life expectancy of someone with your diagnosis?” I couldn’t speak for a moment, all of
                        the sudden it became a holy moment where Jesus walked into the room and that promise of
                        “there will be another option” came back to my heart. It felt like the Lord tucked me into Him in
                        and I knew I had to respond in faith. I lifted my head and looked at her with tears in my eyes and
                        said “have you ever seen a miracle?” she responded with a little laugh “--ha, no - the only
                        miracle I’ve seen is if people live a little longer than expected.” I said with tears back to her “well,
                        God’s going to heal me and there will be a miracle.” I walked out of the room and knew I
                        wouldn’t be returning to that same doctor.
                    </h3>
                    <h3 style={styles.textDescription}>God divinely orchestrated my next steps and care team. Within a week I was connected to a
                        new oncologist and team that were the answers to many prayers. <br /> <br />
                        I went to a holistic clinic for a week in Arizona where I received various treatments and
                        therapies. I visited a church and without knowing the details of my diagnosis the pastor
                        prophesied over me that God would heal me and there would be many miracles. He said God
                        was going to use various methods to bring about the healing which was confirming for me
                        because God had showed me a toolbelt and that He would use all the tools to bring about the
                        healing. I went back to New York and began weekly chemotherapy, and continued holistic
                        therapies, fasting, and alot of face to the floor prayer. My numbers began to go down drastically
                        and the tumor was physically shrinking. Although it was a tender season and I felt somewhat
                        physically fragile, I never was fully down and out or sick. There was a supernatural strength with
                        me. In the midst of the most pain I’ve ever felt - I also felt the Lord with me. It was the most
                        tangible place I’ve experienced with the Lord. There were mornings I would wake up in despair
                        with a tear soaked pillow, and His spirit would come with peace and fill my room and still my
                        heart. It was as if every morning He came to my bedside and asked “will you trust me here?” I
                        was brought back to the moments of asking “God where are you in this?” I saw how He was
                        showing me He was right there asking me to trust. There were so many ‘impossible’ moments
                        throughout the journey that somehow He made a way. At the three month mark of treatment, I
                        was due for my next scan. The night before the scan some friends came over and we prayed,
                        they were filled with faith that the scan would be clear. I wanted to believe that too but I also
                        didn’t want to get my hopes up to be disappointed again. It was a faith and fear tug-o-war.
                        <br />
                        <br />
                        After the scan, I waited in a room to hear the results. My new oncologist walked in and smiled
                        and said “NED, no evidence of disease - you’re cancer free.” It still makes me weep
                        remembering that moment. Those words confirmed in the natural, the supernatural miracle that
                        had taken place. I believe God allowed me to have some of the best doctors and nurses, used
                        medicine and holistic approaches to bring healing - but ultimately He healed my body.
                        <br />
                        <br />
                        I would love to say that in that moment I was set free of all the fear, doubt, and wrestling with the
                        Lord. But it was the start of now letting Him heal my heart. The disappointment that had come
                        with cancer was so heavy I realized I had made agreements with death in moments that pain
                        turned to apathy and apathy turned to despair. Proverbs 13:12 says “Hope deferred makes the
                        heart sick” deep sadness had spread to my heart. I was longing to feel the joy and freedom of
                        the physical healing but my spirit was still being healed from the loss and grief. God gently
                        showed me He wanted to heal every place, there was new life before me. I began to share my
                        healing testimony and as I did - joy and renewed faith came back into me. Sharing the miracle
                        of what God did began to rebuild my heart, I was able to receive His healing fully. I began to
                        recount all of the ways He was faithful to me and broke agreements with premature death.
                        Instead of picturing a funeral, I began to dream about my life and what God would do. No
                        earthly diagnosis or statistic could write the story of my life, only He can. I am three years
                        cancer free today. It’s wild to think that when I used to see death - God saw this moment - His
                        testimony of healing being shared, I am so grateful. He is faithful to every place we entrust into
                        His hands and even better than promises being fulfilled - He is enough in the midst of anything
                        we face. When suffering meets hope - when death meets Jesus, miracles happen. <br /> <br />
                        Psalm 119:129 “Your testimonies are wonderful, therefore my soul keeps them.”
                        <br />
                        <br />

                    </h3>
                </div>

                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/QxjJ3DKf/4.png"
                        //      height="650"
                        height={screenSize > 800 ? ('520px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("100%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    {/* <iframe
                        height={screenSize > 800 ? ('520px') : ("170px")}
                        width={screenSize > 800 ? ("924") : ("80%")}
                        src="https://www.youtube.com/embed/glUBrILRj60" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe> */}
                </div>
                <div style={styles.singleFooter}>
                    <div style={styles.line} />
                    <div style={styles.textDate}>JUNE 20TH 2021</div>
                </div>
                <h2 style={styles.moreStoriesText}>MORE STORIES</h2>
                <div style={styles.storiesContainer}>
                    <Link to="/stories/holly"
                        onMouseOver={() => handleHover(true, "1")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "1" ? (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/Bv9PbrcK/Holly-Hover.png"
                                        src="https://i.postimg.cc/xTqXCCWy/Holly-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/X7Fd25d6/Holly.png"
                                        src="https://i.postimg.cc/PrLqH7wS/Holly.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/mia-and-joren"
                        onMouseOver={() => handleHover(true, "3")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "3" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/VL0Nt7NB/Mia-Joren-Hover.png"
                                        src="https://i.postimg.cc/7PRxKNW0/Mia-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/vBkxh8mm/Mya-Joren.png"
                                        src="https://i.postimg.cc/LsvSLkPq/Mia.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/baylor"
                        onMouseOver={() => handleHover(true, "4")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "4" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/L4zCsStz/Baylor-Hover.png"
                                        src="https://i.postimg.cc/R00SPb41/Baylor-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/qMXghx2q/Baylor.png"
                                        src="https://i.postimg.cc/dV5vTmrh/Baylor.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                </div>
            </div>
        </>
    );
};
