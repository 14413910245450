import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Video3 from "../components/Video-03";

function Video03Container() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoverUrl, setHoverUrl] = useState("");

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            //  console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            //   console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Cindy's Story | But God Book</title>
                <meta
                    name="description"
                    content="Watch Cindy Lake's testimony of her unwavering faith and determination in becoming a mother despite numerous miscarriages, through trusting in God."
                />
            </Helmet>
            <Video3
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>
    );
}

export default Video03Container;