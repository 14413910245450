import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "../components/NavbarWhite";
import HoverImage from "react-hover-image";

//let year = new Date().getFullYear()

let verse = "And you were dead in the trespasses and sins in which you once walked, following the course of this world, following the prince of the power of the air, the spirit that is now at work in the sons of disobedience— among whom we all once lived in the passions of our flesh, carrying out the desires of the body and the mind, and were by nature children of wrath, like the rest of mankind. But God, being rich in mercy, because of the great love with which he loved us, even when we were dead in our trespasses, made us alive together with Christ—by grace you have been saved— and raised us up with him and seated us with him in the heavenly places in Christ Jesus,  so that in the coming ages he might show the immeasurable riches of his grace in kindness toward us in Christ Jesus. For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, not a result of works, so that no one may boast. For we are his workmanship, created in Christ Jesus for good works, which God prepared beforehand, that we should walk in them."
let verseFinal = verse.toUpperCase()

export default ({ screenSize, handleHover, hoverUrl, darkModeState, handleHoverOut, handleChange, handleSubmit, language, page, firstName, lastName, numberPhone, mail, message, messageSent }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")}`,
            color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px 0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 10vh") : ("30vh 0px 10vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
        },
        storiesContainer: {
            display: "flex",
            flexDirection: 'row',
            //alignItems: 'center',
            justifyContent: 'center',
            // fontSize: 20,
            // color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ("0px 60px") : ("0px 20px")}`,
            margin: `${screenSize > 800 ? ("0px 0px 50px") : ("0px 0px 20px")}`,
            //  backgroundColor: 'yellow'
        },
        storiesContainerLeft: {
            display: "flex",
            flex: 1,
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            fontSize: 20,
            color: '#000',
            width: '100%',
            //     height: '4000px',
            //   padding: '0px 10px',
            //     backgroundColor: 'green',
        },
        storiesContainerRight: {
            display: "flex",
            flex: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            fontSize: 20,
            color: '#000',
            //      height: '3200px',
            //   padding: '0px 10px',
            margin: `${screenSize > 800 ? ("250px 0px 0px") : ("70px 0px 0px")}`,
            width: '100%',
            //    backgroundColor: 'red',
        },

        photo: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ("10px") : ("15px")}`,
            padding: `${screenSize > 800 ? ("10px") : ("0px")}`,

            // backgroundColor: '#000'
        },
        photo2: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            margin: `${screenSize > 800 ? ("10px") : ("15px")}`,
            padding: `${screenSize > 800 ? ("10px") : ("0px")}`,
            //    padding: '20px',
            // backgroundColor: '#fff'
        },
        image: {
            display: "flex",
            height: '700px',
            width: '411px',
            backgroundColor: 'purple',
        },

        photoDetail: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: '740px',
            width: '451px',
            backgroundImage: `url(${hoverUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            margin: '0px 0px 20px'
        },

        photoDetailInfo: {
            display: "flex",
            flexDirection: 'column',
            alignItem: 'center',
            justifyContent: 'center',
            height: '740px',
            width: '451px',
            backgroundColor: 'white',
            margin: '0px 0px 20px',
            backgroundColor: 'rgba(255, 255, 255, 60%)'
        },

        photoDetailInfoTitle: {

            display: "flex",
            flexDirection: 'column',
            alignItem: 'center',
            justifyContent: 'center',
            height: '740px',
            width: '451px',
            backgroundColor: 'white',
            margin: '0px 0px 20px',
            //   backgroundColor: 'rgba(255, 255, 255, 60%)'

            backgroundColor: 'yellow'

            // display: "flex",
            // flex: 1
        },
        photoDetailInfoName: {
            display: "flex",
            flex: 1
        },
        photoDetailInfoButton: {
            display: "flex",
            flex: 1
        },


        textDescription: {
            margin: "30px 30vw",
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "100px 0px"
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            width: `${screenSize > 800 ? ('391px') : ("70vw")} `,
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: `${screenSize > 800 ? ("100px 0px 130px") : ("50px 40px 70px")} `,
            backgroundColor: '#000',
            borderRadius: '50px',
            border: '1.5px solid #fff',
        },
        textbutton: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#fff',
            display: "flex",
            alignSelf: 'center',
            margin: 0
        },
        buttonEshop2: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            width: `${screenSize > 800 ? ('391px') : ("70vw")} `,
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: `${screenSize > 800 ? ("0px 0px 130px") : ("0px 40px 70px")} `,
            backgroundColor: '#fff',
            borderRadius: '50px',
            border: '2px solid #000',
        },
        textbutton2: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#000',
            display: "flex",
            alignSelf: 'center',
            margin: 0
        },
        textDescriptionSpecial: {
            margin: `0px`,
            fontSize: `${screenSize > 800 ? ('67px') : ("18px")} `,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: `${screenSize > 800 ? ('78px') : ("23px")} `,
            fontWeight: 400,
            textAlign: 'center'
        },
        textsMargin: {
            display: 'flex',
            flexDirection: 'column',
            margin: '50px 0px'
        },
        footer: {
            display: "flex",
            margin: `${screenSize > 800 ? ("0px 60px 100px") : ("0px 20px 100px")} `,
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "center",
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: "216px",
            height: "2px",
            // alignSelf: 'flex-end',
            // justifyContent: 'flex-end',
            //  left: "648px",
            //    top: "930px",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: `${screenSize > 800 ? ("0px 20px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#fff',
            fontFamily: "BigCaslon",
            outline: 0,
            backgroundColor: 'transparent',
            border: 'none'
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: "0px 0px 100px",
            padding: '0px 40px'
        },
        textDescription: {
            margin: "3px 0px",
            fontSize: `${screenSize > 800 ? ("20px") : ("16px")} `,
            color: '#000',
            lineHeight: "19px",
            fontFamily: "BigCaslon",
            textAlign: 'center'
        },
        textsDiv: {
            display: 'flex',
            alignItems: "space-between",
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: `${screenSize > 800 ? ("30px 0px") : ("8px 0px")}`,
            // backgroundColor: 'green',
        },
        nameStoriesText: {
            fontSize: `${screenSize > 800 ? ("22px") : ("12px")}`,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            // fontWeight: 700,
            writingMode: "tb-rl",
            transform: "rotate(-180deg)",
        },
        numberStoriesText: {
            fontSize: `${screenSize > 800 ? ("18px") : ("8px")}`,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            // fontWeight: 700,
        },
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{ height: 41 }} />
                <h1 style={styles.titleContainer}>STORIES</h1>
                <div style={styles.storiesContainer}>
                    <div style={styles.storiesContainerLeft}>
                        <Link to="/stories/holly"
                            onMouseOver={() => handleHover(true, "1")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "1" ? (
                                    <div style={styles.photo}>
                                        <img
                                            src="https://i.postimg.cc/xTqXCCWy/Holly-Hover.png"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("100%") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>HOLLY / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>01/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            src="https://i.postimg.cc/PrLqH7wS/Holly.png"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("100%") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>HOLLY / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>01/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                        <Link to="/stories/mia-and-joren"
                            onMouseOver={() => handleHover(true, "3")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "3" ? (
                                    <div style={styles.photo}>
                                        <img
                                            src="https://i.postimg.cc/7PRxKNW0/Mia-Hover.png"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("100%") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>MIA & JOREN / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>03/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            src="https://i.postimg.cc/LsvSLkPq/Mia.png"
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("100%") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>MIA & JOREN / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>03/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                        <Link to="/stories/joanne"
                            onMouseOver={() => handleHover(true, "5")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "5" ? (
                                    <div style={styles.photo}>
                                        <img
                                            // src="https://i.postimg.cc/nr7LfxFk/joanne2newhover.png"
                                            src="https://i.postimg.cc/jj68TM7p/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0759-RETOUCHED.jpg"
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            // width={screenSize > 800 ? ("690px") : ("100%")}
                                            // width={screenSize > 800 ? ("100%") : ("100%")}
                                            // width={"664px"}
                                            // height={"100%"}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>JOANNE MOODY</div>
                                            <div style={styles.numberStoriesText}>05/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            src="https://i.postimg.cc/jj68TM7p/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0759-RETOUCHED.jpg"
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            style={{ padding: 22 }}
                                            // width={screenSize > 800 ? ("100%") : ("100%")}
                                            // width={"664px"}
                                            // height={"100%"}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>JOANNE MOODY</div>
                                            <div style={styles.numberStoriesText}>05/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                        <Link to="/stories/sharon"
                            onMouseOver={() => handleHover(true, "7")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "7" ? (
                                    <div style={styles.photo}>
                                        <img
                                            // src="https://i.postimg.cc/NfGWXrkt/sharon-hover.png"
                                            src="https://i.postimg.cc/L544DQt2/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0077-RETOUCHED.jpg"
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            // width={screenSize > 800 ? ("690px") : ("100%")}
                                            // width={screenSize > 800 ? ("100%") : ("100%")}
                                            // width={"664px"}
                                            // height={"100%"}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>SHARON TAYLOR</div>
                                            <div style={styles.numberStoriesText}>07/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            // src="https://i.postimg.cc/gcHCrKR4/sharon.png"
                                            src="https://i.postimg.cc/L544DQt2/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0077-RETOUCHED.jpg"
                                            style={{ padding: 22 }}
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            // width={screenSize > 800 ? ("100%") : ("100%")}
                                            // width={"664px"}
                                            // height={"100%"}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>SHARON TAYLOR</div>
                                            <div style={styles.numberStoriesText}>07/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                        <Link to="/stories/jessica"
                            onMouseOver={() => handleHover(true, "9")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "9" ? (
                                    <div style={styles.photo}>
                                        <img
                                            // src="https://i.postimg.cc/CMCCzPNJ/Jess-main2.png"
                                            src="https://i.postimg.cc/3N0SsYCB/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0152-RETOUCHED.jpg"
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            // width={screenSize > 800 ? ("690px") : ("100%")}
                                            // width={screenSize > 800 ? ("100%") : ("100%")}
                                            // width={"664px"}
                                            // height={"100%"}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>JESSICA STROPKO</div>
                                            <div style={styles.numberStoriesText}>09/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            // src="https://i.postimg.cc/CLNWH4Fs/Jess-main-new.png"
                                            src="https://i.postimg.cc/3N0SsYCB/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0152-RETOUCHED.jpg"
                                            style={{ padding: 22 }}
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            // width={screenSize > 800 ? ("100%") : ("100%")}
                                            // width={"664px"}
                                            // height={"100%"}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>JESSICA STROPKO</div>
                                            <div style={styles.numberStoriesText}>09/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                    </div>
                    <div style={styles.storiesContainerRight}>
                        <Link to="/stories/sarah"
                            onMouseOver={() => handleHover(true, "2")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "2" ? (
                                    <div style={styles.photo}>
                                        <img
                                            src="https://i.postimg.cc/Jz50YkJ8/Sarah-Hover.png"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("100%") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>SARAH / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>02/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            src="https://i.postimg.cc/50T2QqcB/Sarah.png"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("100%") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>SARAH / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>02/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                        <Link to="/stories/baylor"
                            onMouseOver={() => handleHover(true, "4")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "4" ? (
                                    <div style={styles.photo}>
                                        <img
                                            src="https://i.postimg.cc/R00SPb41/Baylor-Hover.png"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("100%") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>BAYLOR / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>04/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            src="https://i.postimg.cc/dV5vTmrh/Baylor.png"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("100%") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>BAYLOR / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>04/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                        <Link to="/stories/alyssa"
                            onMouseOver={() => handleHover(true, "6")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "6" ? (
                                    <div style={styles.photo}>
                                        <img
                                            // src="https://i.postimg.cc/VLFZVjm6/alyssa-hover.png"
                                            src="https://i.postimg.cc/K88LjQCX/2024-06-30-But-God-2024-Portraits-Nashville-Corwin1502-RETOUCHED.jpg"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>ALYSSA / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>06/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            // src="https://i.postimg.cc/RhbJc7Pb/alyssa-cover-new.png"
                                            src="https://i.postimg.cc/K88LjQCX/2024-06-30-But-God-2024-Portraits-Nashville-Corwin1502-RETOUCHED.jpg"
                                            style={{ padding: 22 }}
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>ALYSSA / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>06/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                        <Link to="/stories/hope"
                            onMouseOver={() => handleHover(true, "8")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "8" ? (
                                    <div style={styles.photo}>
                                        <img
                                            // src="https://i.postimg.cc/qMNj2Q9K/hope-darst-hover.png"
                                            src="https://i.postimg.cc/85NTkXnM/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0237-RETOUCHED.jpg"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>HOPE / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>08/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            // src="https://i.postimg.cc/VL24gn8V/Hope-darst-main.png"
                                            src="https://i.postimg.cc/85NTkXnM/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0237-RETOUCHED.jpg"
                                            style={{ padding: 22 }}
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>HOPE / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>08/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                        <Link to="/stories/lauren"
                            onMouseOver={() => handleHover(true, "10")}
                            onMouseOut={() => handleHoverOut(false, "")}>
                            <>
                                {hoverUrl == "10" ? (
                                    <div style={styles.photo}>
                                        <img
                                            src="https://i.postimg.cc/y6tR0Wn2/lauren1.jpg"
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>LAUREN / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>10/10</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={styles.photo2}>
                                        <img
                                            src="https://i.postimg.cc/y6tR0Wn2/lauren1.jpg"
                                            style={{ padding: 22 }}
                                            //  width={screenSize > 800 ? ("650px") : ("100%")}
                                            //   width={screenSize > 800 ? ("690px") : ("100%")}
                                            width={screenSize > 800 ? ("664px") : ("100%")}
                                            loading="lazy"
                                        />
                                        <div style={styles.textsDiv}>
                                            <div style={styles.nameStoriesText}>LAUREN / BUT GOD.</div>
                                            <div style={styles.numberStoriesText}>10/10</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        </Link>
                    </div>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>FROM THE FACELESS TO THE FAMOUS,</h3>
                    <h3 style={styles.textDescription}>FROM THE RICH TO THE POOR,</h3>
                    <h3 style={styles.textDescription}>NO ONE IS EXEMPT FROM MY LOVE.</h3>
                    <h3 style={styles.textDescription}>THESE ARE MY STORIES OF HOPE.</h3>
                    <h3 style={styles.textDescription}>NOW GO TELL THEM.</h3>
                    <h3
                        style={{
                            margin: "3px 0px",
                            marginTop: `${screenSize > 800 ? ("30px") : ("10px")} `,
                            fontSize: `${screenSize > 800 ? ("20px") : ("16px")} `,
                            color: '#000',
                            lineHeight: "19px",
                            fontFamily: "BigCaslon",
                            textAlign: 'center'
                        }}>**Please note stories after Baylor do not feature in the But God Book.</h3>
                </div>
                <button
                    style={styles.buttonEshop2}
                    onClick={() => window.open("https://www.shop.butgodbook.co/product-page/but-god", "_blank")}
                >
                    <h1 style={styles.textbutton2}>Shop</h1>
                    <img
                        src="https://i.postimg.cc/tJ61cwP3/left-arrow.png"
                        height="24"
                        alt="Image"
                    />
                </button>
                <div style={styles.footer}>
                    <div style={styles.singleFooter}>
                        <div style={styles.socialOptions}>
                            {/* <button style={styles.textSocial} onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}>Instagram</button>
                            <button style={styles.textSocial} onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}>YouTube</button>
                            <button style={styles.textSocial} onClick={() => window.open('mailto:info@butgodbook.co')}>Email</button> */}
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/9X4R0hHp/instagram-black.png"
                                height="20"
                                onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}
                                alt="Icon of Instagram"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/8PQ5CfHp/email-black.png"
                                height="20"
                                onClick={() => window.open('mailto:info@butgodbook.co')}
                                alt="Icon of Email"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/1X63Hy91/youtube-black.png"
                                height="20"
                                onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}
                                alt="Icon of YouTube"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
