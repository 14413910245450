/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "./NavbarTransparent";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleHover, handleHoverOut, hoverUrl }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            // backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            backgroundColor: '#000',
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")}`,
            color: '#fff',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 0vh") : ("30vh 0px 5vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
            position: "relative",
            zIndex: '100',
            top: `${screenSize > 800 ? ('70px') : ('140px')} `,
            left: 0,
            // right: 0,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: 20,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        textDescriptionbig: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: `${screenSize > 800 ? ("70px") : ("30px")}`,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: `${screenSize > 800 ? ("70px") : ("30px")}`,
            marginTop: 50,
            marginBottom: 50,
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            gap: '20px',
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "50px 0px",
            flexWrap: `${screenSize > 800 ? ("nowrap") : ("wrap")}`,

        },
        imagePadding: {
            padding: '10px',
            // backgroundColor: '#000',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            width: `${screenSize > 800 ? (<></>) : ("80%")} `,
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: '391px',
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: "100px 0px 130px",
            // backgroundColor: 'red',
            borderRadius: '50px',
            border: '1px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        footer: {
            display: "flex",
            margin: "0px 60px 150px",
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: `${screenSize > 800 ? ("0px 0px 0px 60px") : ("0px 20px")}`,
            margin: '50px 0px 0px'
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: `${screenSize > 800 ? ("921px") : ("80vw")}`,
            height: "2px",
            alignSelf: 'flex-start',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: "0px 20px 0px",
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        textDate: {
            display: "flex",
            margin: "20px 0px 0px",
            fontSize: 12,
            color: '#000',
            lineHeight: "14px",
        },
        moreStoriesText: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            margin: "100px 0px 83px",
            margin: `${screenSize > 800 ? ("100px 0px 83px") : ("70px 0px 30px")}`,
            //margin: "30px 30vw",
            fontSize: 12,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            fontWeight: 700
        },
        storiesContainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: 20,
            // color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ("0px 50px") : ("0px 5px")}`,
            margin: `${screenSize > 800 ? ("0px 0px 150px") : ("0px 0px 100px")}`,
            // backgroundColor: 'yellow'
        },
        photo: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            //  margin: '0px 10px'
            margin: '0px'
        },

        image: {
            display: "flex",
            height: '700px',
            width: '411px',

            backgroundColor: 'purple',
            // backgroundImage: `url(${hoverUrl})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
        },


        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    fontSize: 150,
                    color: '#000',
                    height: "436px",
                    backgroundColor: '#000',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                    margin: "0px 0px 150px"
                }}>
                    <h1 style={styles.titleContainer}>LAUREN</h1>
                </div>
                <div style={styles.imageContainer}>
                    <div style={styles.imagePadding}>
                        <img
                            src="https://i.postimg.cc/y6tR0Wn2/lauren1.jpg"
                            //    height="1382"
                            height={screenSize > 800 ? ('829px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover" }}
                            alt="Image"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>
                        “Lauren…you’re already living your greatest, highest, truest love story” - her words hit me like a ton of bricks, not falling onto me but lifting off me, and the tears began to fall. I had been waiting to fall in love and find my God-ordained partner in life for over a decade, and it had been HARD. There’s no real way to explain what revelation is except to say it feels like a belief drops from your head into your heart as you receive “proof” for its truth. And in that moment in that songwriting room as my friend Hope spoke those anointed words to me, I felt that truth drop into my spirit as God illuminated how His love for me was greater than the love story I longed for. That I could live fully in love and fully cherished and covered here and now, in the waiting. And yet within that surrender was also a deep knowing that He had already written the story of my dreams featuring the man of my dreams and had set the perfect time and setting for it to unfold. <br /> <br />

                        Jordan and I discovered we had spent the better part of 7 years in the same room once a week (or more) at the same church, having radical encounters with God at the same time, feet apart, both dreaming of sharing it with someone else who understood. That became the deepest cry of my heart the longer I was planted in this house of worship (shoutout to @thebelongingco). I still don’t know why it took us that long to meet but I know that it was God’s intention for our very best and our relationship has been so blessed because we both walked the journey with God to get there! Anyway let me back up… <br /> <br /> <br />

                    </h3>

                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/0jMJLq5t/lauren2.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />

                </div>
                <div style={styles.textContainer}>

                    <h3 style={styles.textDescription}>

                        I got asked to lead worship for a DNA night at church on Monday February 14th, 2022 and there was no shortage of tears on my end as I accepted that position knowing I had no other plans that night. My parents got engaged on Valentine’s Day in 1984, my dad proposing with a heart-shaped diamond, and I always found that day so full of wonder and beauty and romance. They made a big deal of celebrating it with my sister and I too, growing up, and both of us wished for heart-shaped diamond rings someday. I was packing up my keyboard at the end of the night and I didn’t have the same buoyancy of joy and hope around Valentine’s Day I had as a young girl, when a sweet (and very cute, ahem) man came over to help me and complimented me on my playing (which is LOL because he’s by far the better piano player between us, I now know) and my heart didn’t register that my life had just turned a corner toward the greatest dream of my heart being realized. But a couple months of weekly “hey how are you!” and “coincidental” parking lot chats later, I knew.
                    </h3>
                    <h3 style={styles.textDescription}>
                        Everyone’s right when they say “you’ll know” when you’ve met your future spouse. And they’re right when they say it’ll almost erase the pain of waiting. And certainly they’re right when they say you’d wait another thousand years for them. To anyone waiting, hoping (or trying to hope) — I see you. It’s gonna happen for you. Don’t hold back the tears. Get to know the God Who grieves with you even as He knows all the good He has in store for you. Your future marriage will be so much better off for your union with God being deepened and strengthened. He’s the author of the love story you dream of and He’s the actual greatest Love you’ll experience, before and after marriage.

                    </h3>
                    <h3 style={styles.textDescription}>
                        Wouldn’t you know it (zoom in on that hand pic) I got a heart-shaped ring next Valentine’s Day from my “best friend forever” and got to know another dimension of God’s love for me. If you’re reading this, you were meant to be reading this and I pray you can hear God saying to you “I’ve got yours too, Beloved”. ❤️ <br /> <br />


                    </h3>


                    {/* QUOTE HERE */}
                    {/* <h3 style={styles.textDescriptionbig}>
                        “Look…I really want us to pray that my husband will come to your wedding with me.”
                    </h3> */}

                </div>


                <div style={styles.imageContainer}>
                    <div style={styles.imagePadding}>
                        <img
                            src="https://i.postimg.cc/tTmxSgbZ/lauren3.jpg"
                            //    height="1382"
                            height={screenSize > 800 ? ('829px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover" }}
                            alt="Image"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div style={styles.singleFooter}>
                    <div style={styles.line} />
                    <div style={styles.textDate}>JUNE 20TH 2021</div>
                </div>
                <h2 style={styles.moreStoriesText}>MORE STORIES</h2>
                <div style={styles.storiesContainer}>
                    <Link to="/stories/holly"
                        onMouseOver={() => handleHover(true, "1")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "1" ? (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/Bv9PbrcK/Holly-Hover.png"
                                        src="https://i.postimg.cc/xTqXCCWy/Holly-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/X7Fd25d6/Holly.png"
                                        src="https://i.postimg.cc/PrLqH7wS/Holly.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/mia-and-joren"
                        onMouseOver={() => handleHover(true, "3")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "3" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/VL0Nt7NB/Mia-Joren-Hover.png"
                                        src="https://i.postimg.cc/7PRxKNW0/Mia-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/vBkxh8mm/Mya-Joren.png"
                                        src="https://i.postimg.cc/LsvSLkPq/Mia.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/baylor"
                        onMouseOver={() => handleHover(true, "4")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "4" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/L4zCsStz/Baylor-Hover.png"
                                        src="https://i.postimg.cc/R00SPb41/Baylor-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/qMXghx2q/Baylor.png"
                                        src="https://i.postimg.cc/dV5vTmrh/Baylor.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                </div>
            </div>
        </>
    );
};
