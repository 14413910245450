import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import About from "../components/About";
import { useHistory } from "react-router-dom";

function AboutContainer() {

    const history = useHistory();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const handleRedirect = (e) => {
        if (e == "exhibition") {
            history.push("/exhibition");
        } else if (e == "stories") {
            history.push("/stories");
        }
    };


    return (
        <>
            <Helmet>
                <title>About But God | But God Book</title>
                <meta
                    name="description"
                    content="Ideated in 2013 by Hannah Corwin, The But God Book aims to highlight God's goodness and captivate every reader with an undeniable sense of God's mircale-working power."
                />
            </Helmet>
            <About
                screenSize={windowWidth}
                handleRedirect={handleRedirect}
            />
        </>

    );
}

export default AboutContainer;