import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Video2 from "../components/Video-02";

function Video02Container() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoverUrl, setHoverUrl] = useState("");

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            //  console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            //   console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Courtney's Story | But God Book </title>
                <meta
                    name="description"
                    content="Watch Courtney Smallbone recount the resurrection power of God in her own life and her baby son's, highlighting the power of faith, prayer, and divine intervention in her life."
                />
            </Helmet>
            <Video2
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>
    );
}

export default Video02Container;