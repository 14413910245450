import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "../components/Navbar2";
import BackToTop from "../components/BackToTop";

let year = new Date().getFullYear()

let verse = "And you were dead in the trespasses and sins in which you once walked, following the course of this world, following the prince of the power of the air, the spirit that is now at work in the sons of disobedience— among whom we all once lived in the passions of our flesh, carrying out the desires of the body and the mind, and were by nature children of wrath, like the rest of mankind. But God, being rich in mercy, because of the great love with which he loved us, even when we were dead in our trespasses, made us alive together with Christ—by grace you have been saved— and raised up with him and seated us with him in the heavenly places in Christ Jesus,  so that in the coming ages he might show the immeasurable riches of his grace in kindness toward us in Christ Jesus. For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, not a result of works, so that no one may boast. For we are his workmanship, created in Christ Jesus for good works, which God prepared beforehand, that we should walk in them."
let verseFinal = verse.toUpperCase()


export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleChange, handleSubmit, language, page, firstName, lastName, numberPhone, mail, message, messageSent }) => {


    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")}`,
            color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px 0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 10vh") : ("30vh 0px 10vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
        },
        // titleContainer: {
        //     display: "flex",
        //     justifyContent: 'flex-start',
        //     fontSize: `${screenSize > 800 ? ("180px") : ("80px")} `,
        //     width: '100vw',
        //     // padding: '0px 50vw 0px 45px',
        //     // margin: "30vh 0px 0px",
        //     padding: `${screenSize > 800 ? ('0px 50vw 0px 45px') : ('0px 50vw 0px 45px')} `,
        //     margin: `${screenSize > 800 ? ("30vh 0px 0px") : ("30vh 0px 0px")} `,
        //     fontFamily: "BigCaslon",
        //     fontWeight: 500,
        //     lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
        //     color: '#000'
        // },
        titleContainer2: {
            display: "flex",
            justifyContent: 'flex-start',
            fontSize: `${screenSize > 800 ? ("180px") : ("60px")} `,
            width: '100vw',
            // padding: '0px 30vw 0px 45px',
            // margin: "0px 0px 20vh",
            padding: `${screenSize > 800 ? ('0px 30vw 0px 45px') : ('0px 10vw 0px 45px')} `,
            margin: `${screenSize > 800 ? ("0px 0px 20vh") : ("0px 0px 10vh")} `,
            fontFamily: "Nimbus",
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
            color: '#000'
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 33vw") : ("20px 20vw")} `,
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        textContainerSpecial: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("50px 0px 100px") : ("50px 0px")} `,
        },
        textDescriptionSpecial: {
            margin: `0px`,
            fontSize: `${screenSize > 800 ? ("44px") : ("22px")}`,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: `${screenSize > 800 ? ("48px") : ("24px")} `,
            fontWeight: 500,
            textAlign: 'justify'
        },
        textDescriptionSpecial2: {
            margin: `0px`,
            fontSize: `${screenSize > 800 ? ("44px") : ("22px")}`,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: `${screenSize > 800 ? ("48px") : ("24px")} `,
            fontWeight: 500,
            textAlign: 'right'
        },
        imageContainer: {
            display: "flex",
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: `${screenSize > 800 ? ("50px 0px") : ("20px 0px")} `,
        },
        videoContainer: {
            // display: "flex",
            // alignItem: 'center',
            // justifyContent: 'center',
            // height: `${screenSize > 800 ? ('650px') : ('')} `,
            // width: '100%',
            // padding: `${screenSize > 800 ? ('20px') : ('40px')} `,
            // backgroundColor: 'white',
            // margin: '100px 0px 100px'
            display: "flex",
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: `${screenSize > 800 ? ("50px 0px") : ("50px 0px")} `,
        },
        videoPadding: {
            padding: '10px',
            backgroundColor: '#000',
        },
        video: {
            display: "flex",
            // height: '100px',
            // width: '100px',
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            width: `${screenSize > 800 ? ('391px') : ("70vw")} `,
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: `${screenSize > 800 ? ("20px 0px 50px") : ("20px 40px 40px")} `,
            backgroundColor: '#000',
            borderRadius: '50px',
            border: '1px solid #000',
        },
        textbutton: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#fff',
            display: "flex",
            alignSelf: 'center',
            margin: 0
        },
        buttonEshop2: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            width: `${screenSize > 800 ? ('391px') : ("70vw")} `,
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: `${screenSize > 800 ? ("0px 0px 130px") : ("0px 40px 70px")} `,
            backgroundColor: '#fff',
            borderRadius: '50px',
            border: '2px solid #000',
        },
        textbutton2: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#000',
            display: "flex",
            alignSelf: 'center',
            margin: 0
        },
        footer: {
            display: "flex",
            margin: `${screenSize > 800 ? ("0px 60px 100px") : ("0px 20px 100px")} `,
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooter: {
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "center",
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: "216px",
            height: "2px",
            // alignSelf: 'flex-end',
            // justifyContent: 'flex-end',
            //  left: "648px",
            //    top: "930px",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        textSocial: {
            margin: `${screenSize > 800 ? ("0px 20px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },

    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            {/* <BackToTop style={{ zIndex: 999 }} /> */}
            <div style={styles.container} id="scroller">
                <div style={{ height: 41 }} />
                <div style={styles.titleContainer}>ABOUT BUT GOD</div>

                {/* <div style={styles.videoContainer}>
                    <ReactPlayer
                        className='react-player'
                        url='/assets/videos/MIA & JOREN _LINERS_V1.mp4'
                        style={styles.video}
                        height={screenSize > 800 ? ("650px") : ("250px")}
                        width={screenSize > 800 ? ("1156px") : ("250px")}
                        controls={true}
                    />
                </div> */}
                <div style={styles.videoContainer}>
                    <div style={styles.videoPadding}>
                        <img
                            src="https://i.postimg.cc/QNk3WKrM/Video.png"
                            // height={screenSize > 800 ? ("90%") : ("100%")}
                            height={screenSize > 800 ? ('520px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover" }}
                            alt="Image"
                        />
                    </div>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>But God is a book that elevates Christ through unique visual testimonies of His love, power and restoration, causing God’s people to be encouraged and inspired.</h3>
                    <h3 style={styles.textDescription}>Visual testimonies seen within the book will be accompanied by the written story. It’s purpose is to show the undeniable power of God, how He has overcome in the lives of the forty people featured.</h3>
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/RVgdYLDr/book.png"
                        //     height={screenSize > 800 ? ("90%") : ("100%")}
                        //  height="721"
                        //   width={screenSize > 800 ? (<></>) : ("100%")}
                        height={screenSize > 800 ? ('721px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <button
                    style={styles.buttonEshop}
                    onClick={() => window.open("https://www.shop.butgodbook.co/product-page/but-god", "_blank")}
                >
                    <h1 style={styles.textbutton}>Pre-Order</h1>
                    <img
                        src="https://i.postimg.cc/W39tVtL4/left-arrow.png"
                        height="24"
                        alt="Image"
                    />
                </button>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>From the faceless to the famous, from the rich to the poor, He is seen through stories of restoration, healing, financial provision, redemption and resurrection. He is seen in every circumstance and glorifIed through people’s faith and hope in Him. He is present and working in every facet and corner of the world. This book highlights God’s goodness. The story of our weakness and Christ’s unmatched strength. The book’s purpose is to reveal Christ and leave the reader/viewer with an undeniable sense of God’s love and power. If God did it for them, He can do it for you. Whatever your background or your circumstances you face, you will be encouraged and inspired by God’s love for you and the those around you.</h3>
                    <h3 style={styles.textDescription}>But God came to be in August 2013 when Hannah Corwin (then Burton) went to visit Uganda and partnered with a charity called Watoto. During that trip Hannah was horrified at the stories and atrocities she was seeing. She cried out to God in desperation ‘God why are these stories not being seen?’</h3>
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/LsDjq9Kc/But-God.png"
                        //     height={screenSize > 800 ? ("90%") : ("100%")}
                        //  height="520"
                        height={screenSize > 800 ? ('520') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        //   width={screenSize > 800 ? (<></>) : ("80%")}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>She felt the Lord prompt her to create a book. She had a vision of a book, the pages turning. Photographs on one side stories on the other. ‘From the faceless to the famous, from the rich to the poor, these are my stories of restoration now go tell them’.</h3>
                    <h3 style={styles.textDescription}>Hannah sat on the vision for some time until 2018 when she felt prompted to start a small collection in nashville, TN.</h3>
                    <h3 style={styles.textDescription}>Fast forward to 2022, she has now created and curated a collection of fifty stories seen in But God’s first publication and the launch exhibition on November 2nd, 2022.</h3>
                </div>

                <div style={styles.textContainerSpecial}>
                    {/* <h3 style={styles.textDescriptionSpecial}>'AND YOU WERE DEAD IN THE TRESPASSES AND SINS IN WHICH YOU ONCE WALKED, FOLLOWING THE COURSE OF THIS WORLD, FOLLOWING THE PRINCE OF THE POWER OF THE AIR, THE SPIRIT THAT IS NOW AT WORK IN THE SONS OF DISOBEDIENCE— AMONG WHOM WE ALL ONCE LIVED IN THE PASSIONS OF OUR FLESH, CARRYING OUT THE DESIRES OF THE BODY AND THE MIND, AND WERE BY NATURE CHILDREN OF WRATH, LIKE THE REST OF MANKIND. BUT GOD, BEI…'</h3> */}
                    <h3 style={styles.textDescriptionSpecial}>{verseFinal}</h3>
                    <h3 style={styles.textDescriptionSpecial2}>EPHESIANS 2:2-10</h3>
                </div>

                <Link
                    to="/hannah"
                    style={styles.buttonEshop2}>
                    <h1 style={styles.textbutton2}>Link to About Hannah Corwin</h1>
                    <img
                        src="https://i.postimg.cc/tJ61cwP3/left-arrow.png"
                        height="24"
                        alt="Image"
                    />
                </Link>
                <div style={styles.footer}>
                    <div style={styles.singleFooter}>
                        <div style={styles.socialOptions}>
                            <h3 style={styles.textSocial}>Instagram</h3>
                            <h3 style={styles.textSocial}>YouTube</h3>
                            <h3 style={styles.textSocial}>Email</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
