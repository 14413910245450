import React, { useState } from 'react'
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "../components/NavbarWhite";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleChange, handleSubmit, language, page, firstName, lastName, numberPhone, mail, message, messageSent }) => {

    const [closeNavbar, setCloseNavbar] = useState(false);

    // const handleClose = () => {
    //     setCloseNavbar(true)
    //     console.log('cambie')
    // }


    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("50px")}`,
            color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px 0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 10vh") : ("30vh 0px 10vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")}`,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 33vw") : ("20px 20vw")} `,
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        textContainerCredits: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("50px 0px 80px") : ("20px 0px")}`,
        },
        textDescriptionCredits: {
            margin: `${screenSize > 800 ? ("2px 33vw") : ("2px 14vw")} `,
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: `${screenSize > 800 ? ("50px 0px") : ("25px 0px")} `,
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: `${screenSize > 800 ? ('391px') : ("70vw")} `,
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            // margin: "50px 0px",
            margin: `${screenSize > 800 ? ("20px 0px 50px") : ("20px 40px 40px")} `,
            backgroundColor: '#fff',
            borderRadius: '50px',
            border: '2px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        buttonEshop2: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: `${screenSize > 800 ? ('391px') : ("70vw")} `,
            padding: `${screenSize > 800 ? ("12px 25px") : ("8px 25px")} `,
            // padding: '0px 50vw 0px 45px',
            //     margin: "10px 0px 130px",
            margin: `${screenSize > 800 ? ("20px 0px 130px") : ("20px 40px 70px")} `,
            backgroundColor: '#fff',
            borderRadius: '50px',
            border: '2px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        textbutton: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#000',
            display: "flex",
            alignSelf: 'center',
            margin: 0
        },
        textbutton2: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#000',
            display: "flex",
            alignSelf: 'center',
            margin: `${screenSize > 800 ? ("0px 100px 0px 0px") : ("0px 10px 0px 0px")} `,
        },
        footer: {
            display: "flex",
            margin: `${screenSize > 800 ? ("0px 60px 100px") : ("0px 20px 100px")} `,
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "center",
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: "216px",
            height: "2px",
            // alignSelf: 'flex-end',
            // justifyContent: 'flex-end',
            //  left: "648px",
            //    top: "930px",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: `${screenSize > 800 ? ("0px 20px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
            outline: 0,
            backgroundColor: 'transparent',
            border: 'none'
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },

    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} closeNavbar={closeNavbar} />
            <div style={styles.container} id="scroller">
                <div style={{ height: 41 }} />
                <h1 style={styles.titleContainer}>EXHIBITION</h1>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>On November 2nd 2022 at a sold out exhibition show 350 people gathered to celebrate the release of The But God Book. The release show was a night filled with fun, laughter, tears and community.</h3>
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/KzKNTBw9/exhibition-01.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/NFmkJYgm/exhibition-02.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/fTQK33KK/exhibition-03.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>With over 40 stories and portraits on display, guests spent the night reading and looking into portraits of evidence of the goodness of God.</h3>
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/BQyJc0jM/exhibition-06.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/9FzW7MQD/exhibition-05.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/wM4grK0x/exhibition-04.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>Guests were able to walk through Saint Elle in Nashville, TN. In an immersive experience of photography, video, and variable medias learning about the But God Book and its journey.</h3>
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/CKCYgnmQ/exhibition-09.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>

                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/tgGywNt6/exhibition-08.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/W1kT5Hrj/exhibition-07.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/RVjzJLYY/exhibition-10.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/SxCb6zFx/exhibition-11.png"
                        //   height="615"
                        //    height={screenSize > 800 ? ("90%") : ("100%")}
                        //   height="492"
                        height={screenSize > 800 ? ('492px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("80%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>

                <div style={styles.textContainerCredits}>
                    <h3 style={styles.textDescriptionCredits}>Photography & Video: Jessica Sheppard</h3>
                    <h3 style={styles.textDescriptionCredits}>Venue: Saint Elle</h3>
                </div>

                <Link
                    onClick={() => window.open("https://www.hannahcorwin.com", "_blank")}
                    style={styles.buttonEshop2}>
                    <h1 style={styles.textbutton}>View Hannah’s Work</h1>
                    <img
                        src="https://i.postimg.cc/tJ61cwP3/left-arrow.png"
                        height="24"
                        alt="Image"
                    />
                </Link>

                {/* <Link
                    onClick={() => window.open("https://www.shop.butgodbook.co/product-page/but-god", "_blank")}
                    style={styles.buttonEshop2}>
                    <h1 style={styles.textbutton2}>ORDER "BUT GOD" BOOK FOR CHRISTMAS </h1>
                    <img
                        src="https://i.postimg.cc/tJ61cwP3/left-arrow.png"
                        height="24"
                        alt="Image"
                    />
                </Link> */}
                <div style={styles.footer}>
                    <div style={styles.singleFooter}>
                        <div style={styles.socialOptions}>
                            {/* <button style={styles.textSocial} onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}>Instagram</button>
                            <button style={styles.textSocial} onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}>YouTube</button>
                            <button style={styles.textSocial} onClick={() => window.open('mailto:info@butgodbook.co')}>Email</button> */}
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/9X4R0hHp/instagram-black.png"
                                height="20"
                                onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}
                                alt="Icon of Instagram"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/8PQ5CfHp/email-black.png"
                                height="20"
                                onClick={() => window.open('mailto:info@butgodbook.co')}
                                alt="Icon of Email"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/1X63Hy91/youtube-black.png"
                                height="20"
                                onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}
                                alt="Icon of YouTube"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
