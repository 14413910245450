import React, { useState } from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from "react-router-dom";


export default ({ closeNavbar }) => {

    const [openNabvar, setOpenNabvar] = useState(false);
    const [openNabvarClick, setOpenNabvarClick] = useState(false);
    const [screenSize, setWindowWidth] = useState(window.innerWidth);

    const handleNavbarClick = () => {

        setOpenNabvar(!openNabvar)

        // if (openNabvarClick == true) {
        //     setOpenNabvarClick(false)
        //     setOpenNabvar(false)
        // } else {
        //     setOpenNabvarClick(true)
        // }
    }

    const handleOpen = () => {
        setOpenNabvar(true)
    }
    const handleClose = () => {
        setOpenNabvar(false)
    }


    const styles = {
        navbarWhite: {
            position: "fixed",
            top: 0,
            right: 0,
            bottom: `${screenSize > 800 ? (<></>) : ("0")} `,
            left: 0,
            display: "flex",
            flexDirection: 'column',
            zIndex: 200,
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(0, 0, 0, .2)',
            fontFamily: "BigCaslon",
        },
        firstLine: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: "145px",
            width: '100%',
            padding: `${screenSize > 800 ? ("20px 0px") : ("20px")}`,
            margin: "0px",
            //   backgroundColor: 'green',
        },
        centerDiv: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center'
        },
        buttonLanguageBlack: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            outline: 0,
            margin: `${screenSize > 800 ? ("0px 10px 0px 60px") : ("0px 10px")}`,
            fontSize: `${screenSize > 800 ? ("12px") : ("12px")}`,
            color: '#000',
            //     width: `${screenSize > 800 ? ("42px") : ("22px")}`,
            width: `${screenSize > 800 ? ("42px") : ("42px")}`,
            fontFamily: "BigCaslon",
        },
        options: {
            display: "flex",
            flex: `${screenSize > 800 ? ("") : (1)} `,
            flexDirection: `${screenSize > 800 ? ("row") : ("column")} `,
            justifyContent: 'space-between',
            alignItems: 'center',
            justifyContent: 'center',
            height: 20,
            width: '100%',
            padding: `${screenSize > 800 ? ("20px 20px") : ("20px")}`,
            margin: "0px",
            //   backgroundColor: 'red',
        },
        textOption: {
            alignSelf: `${screenSize > 800 ? (<></>) : ('center')} `,
            margin: `${screenSize > 800 ? ("0px 20px") : ("10px 40px")} `,
            fontSize: `${screenSize > 800 ? ("14px") : ("22px")} `,
            color: '#000',
            fontFamily: "BigCaslon",
            textAlign: 'center'
        },
        lastLine: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column-reverse")}`,
            justifyContent: 'space-between',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 50,
            width: '100%',
            //   padding: `${screenSize > 800 ? ("20px 120px") : ("20px")}`,
            padding: `${screenSize > 800 ? ("20px 0px") : ("0px 20px")}`,
            margin: "20px 0px 20px 0px",
            //    backgroundColor: 'purple',
        },
        textCopyRight: {
            margin: `${screenSize > 800 ? ("0px 0px 0px 60px") : ("10px 0px 0px")}`,
            fontSize: 12,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: `${screenSize > 800 ? ('0px 40px 0px 0px') : ("10px 0px 20px")}`,
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        navbarTransparent: {
            position: "fixed",
            top: 0,
            right: 0,
            // bottom: 0,
            left: 0,
            display: "flex",
            flexDirection: 'column',
            zIndex: 200,
            borderBottom: '1px solid rgba(0, 0, 0, .2)',
            backgroundColor: 'white',
            fontFamily: "BigCaslon",
        },
        buttonLanguageWhite: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            outline: 0,
            margin: `${screenSize > 800 ? ("0px 40px") : ("0px 20px")}`,
            fontSize: 12,
            color: '#FFF',
            //     width: `${screenSize > 800 ? ("42px") : ("22px")}`,
            width: `${screenSize > 800 ? ("42px") : ("42px")}`,
            fontFamily: "BigCaslon",
        },
        textButtonLanguageHeader: {
            margin: 0,
            padding: 0,
            fontSize: 12,
            color: '#FFF',
        },
        logoContainer: {
            display: "flex",
            alignItems: "center",
            //   padding: "20px 0px"
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: '999'
        },
        lastLineOut: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column-reverse")}`,
            justifyContent: 'space-between',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 1,
            width: '100%',
            //   padding: `${screenSize > 800 ? ("20px 120px") : ("20px")}`,
            padding: 0,
            margin: "0px",
            backgroundColor: 'transparent',
        }
    }
    return (

        <>
            {openNabvar ? (
                <div style={styles.navbarWhite} >
                    <div style={styles.firstLine}>
                        <div style={styles.centerDiv}>
                            <button
                                style={styles.buttonLanguageBlack}
                                onClick={() => handleNavbarClick()}>CLOSE</button>
                        </div>
                        <Link style={styles.logoContainer} to="/" >
                            <img
                                src="https://i.postimg.cc/hP72wdTp/But-God-Logo-Black.png"
                                height={screenSize > 800 ? ("61px") : ("41px")}
                                alt="Logo of But God"
                            />
                        </Link>
                        <div>
                            <div style={styles.buttonLanguageBlack}></div>
                        </div>
                    </div>
                    <div style={styles.options}>
                        <Link style={styles.textOption} to="/about">ABOUT BUT GOD</Link>
                        <Link style={styles.textOption} to="/hannah-corwin">ABOUT HANNAH CORWIN</Link>
                        <Link style={styles.textOption} to="/exhibition">EXHIBITION</Link>
                        <Link style={styles.textOption} to="/stories">STORIES</Link>
                        <Link style={styles.textOption} to="/videos">VIDEOS</Link>
                        <Link style={styles.textOption} to="/behind-the-scenes">BEHIND THE SCENES</Link>
                        <Link style={styles.textOption} onClick={() => window.open("https://www.shop.butgodbook.co/shop", "_blank")}>SHOP</Link>
                        <Link style={styles.textOption} to="/contact">CONTACT US</Link>
                        {/* <h3 style={styles.textOption}></h3>
                        <h3 style={styles.textOption}></h3>
                        <h3 style={styles.textOption}></h3>
                        <h3 style={styles.textOption}></h3> */}
                    </div>
                    <div style={styles.lastLine}>
                        <h3 style={styles.textCopyRight}>COPYRIGHT @2022 BUT GOD LLC</h3>
                        <div style={styles.socialOptions}>
                            {/* <h3 style={styles.textSocial}>INSTAGRAM</h3>
                            <h3 style={styles.textSocial}>YOUTUBE</h3>
                            <h3 style={styles.textSocial}>EMAIL</h3> */}
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/9X4R0hHp/instagram-black.png"
                                height="20"
                                onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}
                                alt="Icon of Instagram"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/8PQ5CfHp/email-black.png"
                                height="20"
                                onClick={() => window.open('mailto:info@butgodbook.co')}
                                alt="Icon of Email"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/1X63Hy91/youtube-black.png"
                                height="20"
                                onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}
                                alt="Icon of YouTube"
                            />
                        </div>
                    </div>
                    <div style={styles.lastLineOut}
                    //   onMouseOver={() => handleClose()}
                    />
                </div>
            ) : (
                <div style={styles.navbarTransparent}>
                    <div style={styles.firstLine}>
                        <div style={styles.centerDiv}>
                            <button
                                //   onMouseOver={() => handleOpen()}
                                //onMouseOut={() => handleClose()}
                                style={styles.buttonLanguageBlack}
                                onClick={() => handleNavbarClick()}>MENU</button>
                        </div>
                        <Link style={styles.logoContainer} to="/" >
                            <img
                                src="https://i.postimg.cc/hP72wdTp/But-God-Logo-Black.png"
                                height={screenSize > 800 ? ("61px") : ("41px")}
                                alt="Logo of But God"
                            />
                        </Link>
                        <div>
                            <div style={styles.buttonLanguageBlack}></div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};
