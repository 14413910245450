import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import axios from 'axios'
import Home from "../components/Home";

function HomeContainer() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    return (
        <>
            <Helmet>
                <title>
                    But God Book by Hannah Corwin - Christian Coffee Table Book
                </title>
                <meta
                    name="description"
                    content="But God is a Christian coffee table book by photographer, Hannah Corwin, featuring inspirational stories of forty people in the form of visual testimonies."
                />
            </Helmet>
            <Home
                screenSize={windowWidth}
            />
        </>
    );
}

export default HomeContainer;