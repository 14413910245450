/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "./NavbarTransparent";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleHover, handleHoverOut, hoverUrl }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            // backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            backgroundColor: '#000',
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")}`,
            color: '#fff',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 0vh") : ("30vh 0px 5vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
            position: "relative",
            zIndex: '100',
            top: `${screenSize > 800 ? ('70px') : ('140px')} `,
            left: 0,
            // right: 0,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: 20,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        textDescriptionbig: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: `${screenSize > 800 ? ("70px") : ("30px")}`,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: `${screenSize > 800 ? ("70px") : ("30px")}`,
            marginTop: 50,
            marginBottom: 50,
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            gap: '20px',
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "50px 0px",
            flexWrap: `${screenSize > 800 ? ("nowrap") : ("wrap")}`,

        },
        imagePadding: {
            padding: '10px',
            // backgroundColor: '#000',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            width: `${screenSize > 800 ? (<></>) : ("80%")} `,
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: '391px',
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: "100px 0px 130px",
            // backgroundColor: 'red',
            borderRadius: '50px',
            border: '1px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        footer: {
            display: "flex",
            margin: "0px 60px 150px",
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: `${screenSize > 800 ? ("0px 0px 0px 60px") : ("0px 20px")}`,
            margin: '50px 0px 0px'
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: `${screenSize > 800 ? ("921px") : ("80vw")}`,
            height: "2px",
            alignSelf: 'flex-start',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: "0px 20px 0px",
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        textDate: {
            display: "flex",
            margin: "20px 0px 0px",
            fontSize: 12,
            color: '#000',
            lineHeight: "14px",
        },
        moreStoriesText: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            margin: "100px 0px 83px",
            margin: `${screenSize > 800 ? ("100px 0px 83px") : ("70px 0px 30px")}`,
            //margin: "30px 30vw",
            fontSize: 12,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            fontWeight: 700
        },
        storiesContainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: 20,
            // color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ("0px 50px") : ("0px 5px")}`,
            margin: `${screenSize > 800 ? ("0px 0px 150px") : ("0px 0px 100px")}`,
            // backgroundColor: 'yellow'
        },
        photo: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            //  margin: '0px 10px'
            margin: '0px'
        },

        image: {
            display: "flex",
            height: '700px',
            width: '411px',

            backgroundColor: 'purple',
            // backgroundImage: `url(${hoverUrl})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
        },


        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    fontSize: 150,
                    color: '#000',
                    height: "436px",
                    // backgroundImage: `url(${"https://i.postimg.cc/Y9c2rNfr/SARAH-GERALD.jpg"})`,
                    backgroundColor: '#000',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                    margin: "0px 0px 150px"
                }}>
                    <h1 style={styles.titleContainer}>SHARON</h1>
                </div>
                <div style={styles.imageContainer}>
                    <div style={styles.imagePadding}>
                        <img
                            src="https://i.postimg.cc/L544DQt2/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0077-RETOUCHED.jpg"
                            //    height="1382"
                            height={screenSize > 800 ? ('829px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover" }}
                            alt="Image"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>In October 2014 I embarked on a journey that I had waited on for many
                        years. One that held hope and promise of a loving lasting partnership
                        through marriage that would grow with time. I thought I had found my
                        husband that I would spend the rest of my life walking alongside, but
                        that’s not exactly how the story played out. <br /> <br />

                        We had about two years of
                        experiencing the beauty and goodness of marriage. We talked and
                        planned for our future family. We worked together to achieve financial,
                        spiritual, emotional, and physical goals.  <br /> <br /> <br />

                        We were blessed to live in the
                        hope and promise of marriage, and I remain grateful for the grace,
                        beauty, strength, and covering of marriage I got to experience during
                        that time. We built this relationship for four years before getting married
                        in 2014.<br /> <br />
                        Marriage is a powerful institution created by God. No matter
                        how well you know yourself or your future spouse. Marriage will sanctify
                        and refine you. That sanctification and refinement cause things to come
                        up and out that you thought had been overcome and sometimes will
                        reveal things in you and/or your spouse that you’re just completely
                        unaware of. It’s the beauty and power of marriage. We both
                        experienced this in our marriage and supported one another as best we
                        could through every revelation.<br /> <br />

                        While we worked through a lot and had glimpses of hope of coming out
                        on the other side with our marriage intact, that’s not how this story goes.
                        Deception, dishonor, substance abuse, and unattended mental health
                        left a trail of pain, despair, and at times hopelessness. During my
                        marriage, I experienced depression, suicidal thoughts, and feelings of
                        worthlessness. <br /> <br />






                    </h3>

                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/XvmcLt53/sharon-1.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    <img
                        src="https://i.postimg.cc/fLd7r57d/sharon-2.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    <img
                        src="https://i.postimg.cc/vBqzpKmV/2024-06-30-But-God-2024-Portraits-Nashville-Corwin0099-RETOUCHED.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    {/* <h3 style={styles.textDescription}>The next day I returned to the city and knew things were about to move quickly with next steps
                        for my treatment plan. My google search history became a diary of what was filling my mind
                        “stage 4 cancer healing” “miracle healing of cancer”“best oncologists in New York” “31 years old
                        stage 4 diagnosis”. I had quickly become very aware of the facts and reality. The whispers of
                        doctors and staff in hospital rooms grew loud and faith - in those environments - started to feel
                        foolish. I had already been crushed so to believe again in this place of what is deemed a
                        “terminal diagnosis” felt like it took everything in me to believe and it’s scary to believe again in a
                        place where you have already been let down. It was almost like “ok God, I believed you would
                        be with me in an early stage diagnosis, but now that it is stage four - that feels like proof you’ve
                        abandoned me and this is it.” I would be full of faith in one moment and the next, I felt depleted
                        of hope. There was a constant tension of faith and fear. Grief became like a bucket of water
                        that wouldn’t empty and sat beside me. I didn’t know what to do or where to start, would I die
                        from this or would God really heal me to the point of fully living life again? I felt half alive some
                        of the days in that season. I fought to get before the Lord in prayer every day, that time with
                        Him was my lifeline.</h3> */}
                    <h3 style={styles.textDescription}>
                        I questioned my ability to be a good wife, a virtuous
                        woman, a loved daughter, a great mom. I wrestled with the vow I made
                        to God to remain
                    </h3>

                    {/* QUOTE HERE */}
                    <h3 style={styles.textDescriptionbig}>
                        “In sickness and in health, for better or worse, till death
                        do us part.”
                    </h3>

                </div>

                <div style={styles.textContainer}>

                    <h3 style={styles.textDescription}> I didn’t take those vows lightly, and I wanted to remain.
                        However, I chose to leave and divorce my husband to keep myself safe
                        and preserve my life and mental health. This was not an easy decision
                        that I made while consulting with the Lord. It took me about a year and a
                        half of being separated from my husband to decide to file for divorce.
                        That was the hardest decision I’ve had to make in my life. Once that
                        filing was unsuccessful due to it being contested, I would have to find
                        the courage to file for divorce a second time. That decision took another
                        year and a half for me to choose to file a second time with the result of
                        the divorce being finalized in March 2020. Not only did I lose my
                        marriage, I also lost motherhood as I was blessed with a beloved bonus
                        child when I got married.
                        <br /> <br />
                        My ex-husband has a beautiful daughter from
                        a previous relationship that I was blessed to get to know, build a
                        relationship and serve as a bonus Mom. My heart has longed to be a
                        mom for a very long time, and I was over the moon excited to
                        experience motherhood in marriage. So not only did I grieve the loss of
                        my marriage, I also grieved the loss of motherhood. It felt like the
                        fulfillment and beautiful settlement of dreams were being physically
                        ripped apart from me.
                        <br />
                        <br />
                        The road to deciding to file for divorce was paved with pain-stricken
                        experiences for both me and my ex-husband, trauma, much prayer and
                        worship, and lots of support for and hope that we would get better.
                        During our separation, I had space to see a bit clearer. To learn how to
                        begin setting healthy boundaries. To learn that boundaries are set for
                        our safety and our good and the safety and good of others.
                        <br />
                        <br />
                        I couldn’t
                        see a light of hope at the end for myself, But God. He never left me
                        during this entire process. As much as he hates divorce, as do I, He
                        remained close to me through the entire process. Talk about a loving
                        Father! A kind God! A God of love. I am unsure what you have heard
                        about Jesus, but He literally bears what He hates for the sake of your
                        freedom. I am beyond grateful for His love and care. I need Him. I need
                        it. There truly is no one like Jesus. Because of His sacrifice and work on
                        the cross, I have a close relationship with God that has healed my
                        heart. He is still healing it in some ways as there are days that I still
                        grieve the loss of my marriage and motherhood. Amid some grief, there
                        is much joy, strength, peace, courage, and confidence that now far
                        outweighs the grief. The grief exists because just like God, I hate
                        divorce and I grieve the beauty and blessing of what once was but is no
                        more. <br /> <br />
                        My hope is that we will see divorce rates majorly decrease. That
                        we will see couples take a deep examination of their relationship,
                        themselves, and their partners before entering the holy union of
                        marriage. That spouses would love God more than they love their
                        spouse. I hope that any married couple that reads my story will hear me
                        say, “Be encouraged. Do everything you can individually and collectively
                        to remain and build a healthy safe marriage for one another. Be quick to
                        forgive one another. Be careful to love, honor, and care for one another.
                        Allow God to lead you in all these things.”

                        <br />
                        <br />

                        Currently, I’m four years into singleness after marriage, and God has
                        restored His joy and peace into my life. I have more joy and peace than
                        I have ever had before. He’s such a good Father to bless me despite
                        me. The joy that I carry is a true supernatural gift from God, and I am so
                        grateful for the gift but most importantly the giver and owner of the gift.
                        This joy of His that I have is truly my strength. It has grown through
                        obedience, steadfastness, and intimacy with the Father, Son, and Holy
                        Spirit. In my singleness, I have such beautiful friendships. I am rich with
                        relationship, with others and myself. I am on journey to get to know the
                        beauty that God created when He created me. The beauty He knew
                        before He formed me in the womb. I see my value as He determined.
                        One to be set apart and appointed. I enjoy life with my community. It’s
                        such a rich and beautiful community. I am beyond grateful to every
                        friend and member of my community who chooses to love me well. Who
                        chooses to care for me and allows me to love, care for, and serve them.
                        We have life experiences together as friends, as family. We travel
                        together and create lasting memories. We celebrate the beginning of
                        marriages at weddings, the start and growth of families at baby
                        showers, and the continuation of life at birthday celebrations.

                        <br />
                        <br />

                        The enemy meant to destroy me and separate me from the love of
                        Jesus, BUT GOD! Rich in grace and mercy, He has restored vitality of
                        life post-divorce. He has healed my heart, mind, and soul. God has
                        blessed me with fresh exciting dreams in my career and renewed hope
                        for my future family. So to every person walking through singleness,
                        pre-marriage, marriage, or singleness post-marriage, I say, “Choose
                        God and His holiness. Choose His peace and joy.” I fully believe and
                        have faith that I will experience the beauty, power, and holiness of
                        marriage again. That I will be in a healthy godly marriage that
                        encourages others. That I will be a Mom. That I will bear children and
                        build a family.

                        <br />
                        <br />

                        Just as God has restored so many parts of my story postdivorce, I believe for restoration of everything that the enemy stole.
                        Even until then and without those things, I know that God is good! I
                        know that I have value and purpose without marriage or children, not
                        because of those blessings. I know that I’m bringing heaven into the
                        marriage and family God has for me. I know that I have seen some dark
                        times in my life, but God has been there to rescue me every time. His
                        light has led me out of darkness into his glorious, wonderful, marvelous
                        light every single time. I know He will remain with me and in me, and I in
                        Him. I am so grateful for Jesus.

                        <br />
                        <br />

                        For without Him, I wouldn’t have access
                        to the Father to experience His nature in the way that I do. My story is
                        not done for God is always moving and working for me. I love Him so
                        much, and I am grateful for Him. I am forever grateful that in difficult
                        circumstances there will always be a pause. A shift. A But God moment.
                        For me, and for you too. You just have to look for it and pursue it. Do not
                        lose hope. Cling to Jesus who is hope.

                    </h3>
                </div>

                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/DzLdCBPX/sharon-primary.jpg"
                        //      height="650"
                        height={screenSize > 800 ? ('520px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("100%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    {/* <iframe
                        height={screenSize > 800 ? ('520px') : ("170px")}
                        width={screenSize > 800 ? ("924") : ("80%")}
                        src="https://www.youtube.com/embed/glUBrILRj60" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe> */}
                </div>
                <div style={styles.singleFooter}>
                    <div style={styles.line} />
                    <div style={styles.textDate}>JUNE 20TH 2021</div>
                </div>
                <h2 style={styles.moreStoriesText}>MORE STORIES</h2>
                <div style={styles.storiesContainer}>
                    <Link to="/stories/holly"
                        onMouseOver={() => handleHover(true, "1")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "1" ? (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/Bv9PbrcK/Holly-Hover.png"
                                        src="https://i.postimg.cc/xTqXCCWy/Holly-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/X7Fd25d6/Holly.png"
                                        src="https://i.postimg.cc/PrLqH7wS/Holly.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/mia-and-joren"
                        onMouseOver={() => handleHover(true, "3")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "3" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/VL0Nt7NB/Mia-Joren-Hover.png"
                                        src="https://i.postimg.cc/7PRxKNW0/Mia-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/vBkxh8mm/Mya-Joren.png"
                                        src="https://i.postimg.cc/LsvSLkPq/Mia.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/baylor"
                        onMouseOver={() => handleHover(true, "4")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "4" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/L4zCsStz/Baylor-Hover.png"
                                        src="https://i.postimg.cc/R00SPb41/Baylor-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/qMXghx2q/Baylor.png"
                                        src="https://i.postimg.cc/dV5vTmrh/Baylor.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                </div>
            </div>
        </>
    );
};
